<template>
  <div>
    <!-- Followers -->
    <div v-if="followers.length > 0">
      <ion-grid>
        <ion-row>
          <ion-col size="4" v-for="follower in followers" :key="follower" class="col-4 text-center ion-text-center">
            <ion-thumbnail class="margin-auto" style="cursor:pointer;">
              <img v-if="follower.user.profile_photo_path != null && follower.user.profile_photo_path != ''" :src="follower.user.profile_photo_path" class="img-avatar w-100 avatar-md" @click.prevent="updateRoute(follower.user.username)">
              <img  v-else src="https://s3-us-west-1.amazonaws.com/artistwavesoftware/users/default.jpg" class="img-avatar w-100 avatar-md" @click.prevent="updateRoute(follower.user.username)">
            </ion-thumbnail>
          </ion-col>          
        </ion-row>
      </ion-grid>
    </div>
    <!-- No Followers -->
    <div v-else class="padding-md">
      This user has no followers
    </div>
  </div>
</template>

<script>
import { loadingController, IonGrid, IonRow, IonCol, IonThumbnail } from '@ionic/vue';
import { defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import apiClient from '../services/api';

export default defineComponent({
  props: ['username'],
  name: 'UserFollowing',
  components: { IonGrid, IonRow, IonCol, IonThumbnail },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const userId = ref(0);
    const followers = ref([]);
    const timeout = { default: 6000 }

    onMounted(() => {
      getUserId()
    })

    function goBack() {
      router.go(-1)
    }

    async function presentLoading() {
      const loading = await loadingController
      .create({
        cssClass: 'my-custom-class',
        message: 'Please wait...',
        duration: timeout,
      });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    function getUserId() {
      presentLoading()

      apiClient.get('/api/get/user_id/'+props.username,
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          userId.value = response.data
        }).catch(error => {
          console.log(error)
        });  
    }

    function getFollowing() {
      presentLoading()

      apiClient.get('/api/get/following/user_id/'+userId.value,
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          followers.value = response.data
        }).catch(error => {
          console.log(error)
        });  
    }

    function updateRoute(val) {
      router.push('/profile/'+val)
    }

    return {
      router, goBack, store, getFollowing, followers, userId, updateRoute
    }
  },
  methods: {

  },
  watch: {
    userId: function() {
      if(this.userId > 0) {
        this.getFollowing()
      }
    }
  }
});
</script>

<style scoped>
ion-thumbnail {
   --size: 80px;
}
</style>
<template>
  <div>
    <!-- Followers -->
    <div v-if="followers.length > 0">
      <ion-list>

        <ion-item v-for="follower in followers" :key="follower">
          <div tabindex="0"></div><!-- bug fix for safari 14 -->
          <ion-avatar slot="start" @click.prevent="updateRoute(follower.user.username)">
            <img v-if="follower.user.profile_photo_path != null && follower.user.profile_photo_path != ''" :src="follower.user.profile_photo_path">
            <img  v-else src="https://s3-us-west-1.amazonaws.com/artistwavesoftware/users/default.jpg">
          </ion-avatar>
          <ion-label @click.prevent="updateRoute(follower.user.username)">
            <h2>{{follower.user.name}} <ion-text color="medium">@{{follower.user.username}}</ion-text></h2>
            <h3 v-if="follower.user.profile.short_description != null && follower.user.profile.short_description != ''">
              {{follower.user.profile.short_description}}
            </h3>
            <p>{{follower.user.location.city}}, {{follower.user.location.state}}</p>
          </ion-label>
          <ion-button v-if="admin === true" slot="end" color="medium" @click.prevent="unFollow(follower.user.id)">
            Unfollow
          </ion-button>
        </ion-item>

      </ion-list>
    </div>
    <!-- No Followers -->
    <div v-else class="padding-md">
      This user has no followers
    </div>
  </div>
</template>

<script>
import { loadingController, IonList, IonItem, IonAvatar, IonLabel, IonButton, toastController, IonText } from '@ionic/vue';
import { defineComponent, onMounted, ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from "vuex";
import apiClient from '../services/api';

export default defineComponent({
  props: ['username'],
  name: 'UserFollowingList',
  components: { IonList, IonItem, IonAvatar, IonLabel, IonButton, IonText },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const authUser = computed(() => store.state.authUser);
    const userId = ref(0);
    const followers = ref([]);
    const timeout = { default: 6000 }
    const admin = ref(false);
    const toastMessage = ref(null);

    onMounted(() => {
      getUserId()
    })

    function goBack() {
      router.go(-1)
    }

    async function presentLoading() {
      const loading = await loadingController
      .create({
        cssClass: 'my-custom-class',
        message: 'Please wait...',
        duration: timeout,
      });

      await loading.present();

      setTimeout(function() {
        loading.dismiss()
      }, timeout);
    }

    async function openToast() {
      const toast = await toastController
      .create({
        //message: '',
        duration: 6000,
        message: toastMessage.value,
        position: 'bottom',
        buttons: [
          {
            icon: 'close',
            role: 'cancel',
            handler: () => {

            }
          }
        ]
      })
      return toast.present();
    }

    function getUserId() {
      presentLoading()

      apiClient.get('/api/get/user_id/'+props.username,
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          userId.value = response.data
        }).catch(error => {
          console.log(error)
        });  
    }

    function getFollowing() {
      presentLoading()

      apiClient.get('/api/get/following/user_id/'+userId.value,
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          followers.value = response.data
        }).catch(error => {
          console.log(error)
        });  
    }

    function updateRoute(val) {
      router.push('/profile/'+val)
    }

    function unFollow(userId) {
      presentLoading()
      apiClient.post('/api/post/unfollow', 
        { 
          user_id: userId,
          follower_id: authUser.value.id    
        },
        {
          headers: {
            'Authorization':  `Bearer ${store.state.authToken}`
          }
        }).then(response => {
          if(response.data.message == 'success') {
            toastMessage.value = 'You have unfollowed this account'
            getFollowing()
          }
          if(response.data.message == 'fail') {
            toastMessage.value = 'Something went wrong.'
          }
          if(response.data.message == 'fail-validation') {
            toastMessage.value = 'You are not authorized to do that.'
          }
          if(response.data.message == 'fail-auth') {
            toastMessage.value = 'You must be signed in.'
          }
          openToast()
        }).catch(error => {
          console.log(error)
        });     
    }

    return {
      router, goBack, store, getFollowing, followers, userId, updateRoute, admin, authUser, unFollow
    }
  },
  methods: {

  },
  watch: {
    userId: function() {
      if(this.userId > 0) {
        this.getFollowing()
        if(this.authUser) {
          if(this.authUser.id === this.userId) {
            this.admin = true
          }
        }
      }
    }
  }
});
</script>

<style scoped>
ion-thumbnail {
   --size: 80px;
}
</style>